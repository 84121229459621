@font-face {
  font-family: "FontAwesome";
  src: url(../fonts/fontawesome-webfont.ttf) format("truetype");
}

.group-conditions .disabled {
  display: none;
}

.padding-0 {
  padding: 0px;
}

.page-content {
  padding: 8px 12px 24px;
}

.select2-container--default .select2-selection--single {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.rule-value-container select, .rule-operator-container select, .rule-filter-container select {
  font-size: 11px;
  max-width: 400px;
}

.rule_definition_container .select2-container {
  font-size: 11px;
  line-height: 12px;
  max-width: 400px;
}

.dataTable tbody tr.selected.current_selected td {
  background-color: #003f33;
  color: white;
}

h4.loadingWidgetHeader {
  line-height: 36px;
  padding: 0;
  margin: 0;
  display: inline;
}

li.active span.title {
  //  font-weight: bold;
}

.form-control {
  padding: 5px;
}

.wizard-table .form-control {
  padding: 2px;
  height: 23px;
  max-height: 23px;
  font-size: 11px;
}

.ajax-loading-icon.blue {
  color: #003f33 !important;
}

.dz-progress {
  display: none;
}

.bootbox .modal-dialog {
  width: 400px !important;
}

.legend-box {
  width: 16px;
  height: 16px;
  border: 1px solid grey;
}

.page-header h1 {
  color: #003f33;
}

.td-right-bordered {
  border-right: 1px solid grey !important
}

.td-left-bordered {
  border-left: 1px solid grey !important
}

.td-top-bordered {
  border-top: 1px solid grey !important
}

.td-bottom-bordered {
  border-bottom: 1px solid grey !important
}

.dataTables_wrapper .select-info {
  display: none;
}